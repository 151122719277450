.project {
    background: white;
    width: auto;
    height: 400px;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    /* add a little bit of shadow */
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5);
    /* round the corners */
    border-radius: 10px;
}