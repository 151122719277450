.myProjects--title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 0;
    /* turn to hex color r:31 g:34 b:43 */
    color: #1f222b;
    align-self: start;
    margin-top: 160px;
}

.myProjects--projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 20px;
    margin: 20px;
}