header {
    display: flex;
    background-color: white;
    height: 100px;
    align-items: center;
    justify-content: start;
    font-size: calc(10px + 2vmin);
    color: black;
    padding: 20px 20px 20px 30px;
    gap: 30px;
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    font-family: 'Bruno Ace SC', cursive;
    opacity: 0.9;
}

.header--pfp {
    max-height: 100%;
}

.header--name-n-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 100%;
    padding: 0;
    cursor: pointer;
}

.header--name, .header--job {
    padding: 0;
    margin: 0;
    margin-right: auto;
    align-self: flex-start;
}